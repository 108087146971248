<template>
    <main class="main mt-8" style="direction:rtl; text-align: right;">
        <div class="row container">
            <div class="col-md-6 login-form">
                <div class="heading mb-4 mt-5">
                    <h2 class="title">تغيير كلمة المرور</h2>
                    <p>ارجو ادخال البريد الالكتروني الخاص بك لارسال لك الكود لتغيير كلمة المرور</p>
                </div>
                <!-- End .heading -->
                <div>
                    <div class="form-group required-field">
                        <label for="mail">البريد الالكتروني</label>
                        <input type="email" class="form-control" id="mail" v-model="mail" required>
                    </div>
                    <!-- End .form-group -->

                    <div class="form-footer">
                        <button @click="password_init" type="submit" class="btn btn-primary">ارسال</button>
                        <a href="/login" class="forget-pass ml-5 mr-5"> تسجيل الدخول ! </a>
                    </div>
                    <!-- End .form-footer -->
                </div>
            </div>
            <div class="col-md-4">
                <img src="../../assets/images/login/Secure login-pana.png" alt="">
            </div>
        </div>
        <!-- End .container -->
        <b-modal size="sm" ref="password-code" centered dir="rtl">
            <template #modal-header>
                <h2>تغيير كلمة المرور</h2>
            </template>
            <div class="form-group required-field">
                <p class="h4 mb-1">رمز التحقق</p>
                <input type="text" class="form-control mb-4" v-model="code" id="code" required>
            </div>

            <div class="form-group required-field">
                <p class="h4 mb-1">كلمة المرور الجديدة</p>
                <input type="text" class="form-control" v-model="newpassword" id="new-password" required>
            </div>
            <template #modal-footer="{ close }">
                <b-button @click="password_finish">تأكيد</b-button>
                <b-button @click="close()">الغاء</b-button>
            </template>
        </b-modal>

        <b-modal size="sm" ref="password-changed" centered >
            <p class="my-4 login-error">تم تغيير كلمة المرور بنجاح</p>
            <img class="login-error-img" src="../../assets/images/notFound/Login-amico.png" alt="">
            <template #modal-footer>
                <b-button block @click="$router.go()">موافق</b-button>
            </template>
        </b-modal>
        <div class="mb-10"></div>
    </main>
</template>

<script>
export default {
    data() {
        return {
            change_password_bool: false,
            personal_info_bool: true,
            code: null,
            currentPassword: '',
            newPassword: '',
            newpassword: '',
            mail: '',
        }
    },
    methods: {
        password_init: function() {
            this.axios({
                url: 
process.env.VUE_APP_URL+'/api/account/reset-password/init',
                method: 'POST',
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
                data: this.mail
            })
            .then(response  => {
                console.log(response)
                this.$refs['password-code'].show()
            })
            .catch(error => {
                console.log(error)
            })
        },
        password_finish: function() {
            this.axios({
                url: 
process.env.VUE_APP_URL+'/api/account/reset-password/finish',
                method: 'POST',
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
                data: {
                    key: this.code,
                    newPassword: this.newpassword,
                },
            })
            .then(() => {
                    this.$refs['password-code'].hide()
                    this.$refs['password-changed'].show()
            })
            .catch(() => {
                
            })
        },
    }
}
</script>

<style scoped>
    .login-form{
        background-color: rgb(255, 255, 255);
        margin-bottom: 79px;
        margin-top: 79px;
        padding: 31px;
        border-radius: 15px;
        font-family: 'Cairo', sans-serif;
        margin-right: 182px;
    }
    .container, .form-footer {
        justify-content: center;
    }
    .form-group, h2, p, div a {
        text-align: -webkit-center;
    }
    img {
        margin-top : -43px
    }
</style>